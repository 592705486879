import React, { FC } from 'react';
import styled from '@emotion/styled';

import { FontSecondarySemiBold } from '../../../styles/fonts';
import { Icon } from '../Icons/Icon';
import { sortPricesByDays } from '../../../helper/prices-helper';
import { readablePrice } from '../../../helper/readable-price';
import { spacingBottom, spacingRight, spacingTop } from '../../../helper/spacing';
import { mediaQueries } from '../../../styles/media-queries';
import {UrlObject} from 'url';
import styles from './product-price.module.scss';
import { ProductFees } from '../../product/product-fees';
import { Tooltip, TooltipList, TooltipListItem } from '@digando/common-ui';
import classNames from 'classnames';

export interface IProductPrice {
  price: string;
  days: number;
}

enum PriceTypeEnum {
  Eq = 'EQ',
  Min = 'MIN'
}

export interface IProductPriceInformation {
  productPricePerDay: {
    amount: number;
    type: PriceTypeEnum;
  };
  productPriceReducedPerDay: {
    amount: number;
    type: PriceTypeEnum;
  };
  workingDays: number;
  serviceFees: {
    key: string;
    value: number;
  }[];
}

export interface IProductPriceProps {
  prices: IProductPrice[];
  productPriceInformation?: IProductPriceInformation | null;
  renderTooltipDays: (days: number) => React.ReactElement;
  pricePerDayLabel: string;
  fromLabel: string;
  detailsLabel?: string;
  link: UrlObject | null;
  serviceFees: {
    key: string;
    value: number;
  }[];
}

export const ProductPrice: FC<IProductPriceProps> = ({
  prices, productPriceInformation, pricePerDayLabel, fromLabel, renderTooltipDays, detailsLabel, link, serviceFees
}) => {
  sortPricesByDays(prices);

  const generalPrice = prices[prices.length - 1];

  const fromPrice = <>{fromLabel} {generalPrice.price}{' '}</>;
  const detailedPrice = <>
    {(productPriceInformation?.productPricePerDay.amount ?? 0) === (productPriceInformation?.productPriceReducedPerDay.amount ?? 0)
      ? <>
        {PriceTypeEnum.Min === productPriceInformation?.productPriceReducedPerDay.type && (<>ab</>)} {readablePrice(productPriceInformation?.productPriceReducedPerDay.amount ?? 0)} / {pricePerDayLabel}
      </>
      : (<>
        <OldPrice>statt {readablePrice(productPriceInformation?.productPricePerDay.amount ?? 0)} / {pricePerDayLabel}</OldPrice>
        {readablePrice(productPriceInformation?.productPriceReducedPerDay.amount ?? 0)} / {pricePerDayLabel}
      </>)
    }
  </>;

  const items: TooltipListItem[] = prices.map((price, index) => ({
    value: price.price,
    key: renderTooltipDays(price.days).props.children,
  }));

  return (
    <div className={classNames({
      [styles.productPrice]: true,
    })}>
      <div className={classNames({
        [styles.priceInfoColumn]: true,
        [styles.onlyDesktop]: true,
      })}>
        <Tooltip tooltipContent={<TooltipList items={items} />}>
          <div className={styles.priceInfoButton}>
            <span className={styles.detailsLabel}>{detailsLabel}</span>
            <Icon icon={'info-outline'} size={'17px'}/>
          </div>
        </Tooltip>
      </div>

      <GeneralPriceColumn>
        {!productPriceInformation ? fromPrice : detailedPrice}
      </GeneralPriceColumn>
      <ProductFees serviceFees={serviceFees} />
    </div>
  );
};

const GeneralPriceColumn = styled.div`
    ${spacingTop(1)};
    ${FontSecondarySemiBold};
    font-size: var(--font-size-headline-h4);
    line-height: 24px;
    color: var(--black);
    white-space: nowrap;
    ${spacingRight(0.5)};

    @media ${mediaQueries.laptopL} {
      ${spacingRight(0)};
      ${spacingBottom(1.5)};

      &:last-of-type {
        ${spacingRight(0.5)};
      }
    }
`;

const OldPrice = styled.div`
  ${FontSecondarySemiBold};
  font-size: var(--font-size-text-small);
  line-height: 14px;
  text-align: right;
  text-decoration: line-through;
`;
