import React, { FC } from 'react';
import Link from 'next/link';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { IPropertyFilter } from '../../hooks/use-product-search-helper';
import { UrlObject } from 'url';
import { scrollPositionState, useProductSearchValues } from '../../store/recoil/product-search';
import { useSetRecoilState } from 'recoil';
import { IDigandoLinkButtonProps, LinkButton } from '@digando/common-ui';
import { RentalStationShippingOptions, ShippingOption } from '../../@types/codegen/graphql';

const { publicRuntimeConfig } = getConfig();

interface IProductLinkProps {
  asButton?: boolean;
  href: string;
  variants?: IPropertyFilter[];
  rentalStationId?: string;
  deliveryAddressId?: string | null;
  productGroupId?: string;
  tenantKey?: string;
  basketItemId?: string;
  buttonProps?: Pick<IDigandoLinkButtonProps, 'spacing' | 'small' | 'bordered' | 'fullWidth' | 'uppercase'>;
  ariaLabel?: string;
  shippingOptions?: RentalStationShippingOptions;
}

interface IProductPageLinkProps extends IProductLinkProps {
  productGroupId?: string;
  tenantKey?: string;
  title?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export const useProductLink = (): { getProductLinkParams: (params: IProductLinkProps) => UrlObject } => {
  const router = useRouter();
  const { filterValues } = useProductSearchValues();

  const getProductLinkParams = ({ href, variants, rentalStationId, deliveryAddressId, tenantKey, basketItemId, shippingOptions }: IProductLinkProps): UrlObject => {
    const url = new URL(href, publicRuntimeConfig.appBaseUrl);

    if (tenantKey) {
      url.searchParams.append('tenantKey', tenantKey);
    }

    if (basketItemId) {
      url.searchParams.append('basketItemId', basketItemId);
    }

    if (rentalStationId) {
      url.searchParams.append('rentalStationId', rentalStationId);
    } else if (router.query.rentalStationId) {
      url.searchParams.append('rentalStationId', router.query.rentalStationId as string);
    }

    if(deliveryAddressId) {
      url.searchParams.append('deliveryAddressId', deliveryAddressId);
    }

    if (variants) {
      if (0 < variants.length) {
        url.searchParams.append('variants', JSON.stringify(variants));
      }
    } else if (router.query.variants) {
      url.searchParams.append('variants', router.query.variants as string);
    }

    if (shippingOptions) {
      if (shippingOptions.pickupEnabled) {
        url.searchParams.append('shippingOption', ShippingOption.Pickup);
      } else if (shippingOptions.deliveryEnabled) {
        url.searchParams.append('shippingOption', ShippingOption.Delivery);
      }
    }

    url.searchParams.append('from', filterValues.dateRange.gte.format('YYYY-MM-DD'));
    url.searchParams.append('to', filterValues.dateRange.lte.format('YYYY-MM-DD'));

    return {
      pathname: url.pathname,
      query: Object.fromEntries(url.searchParams),
    };
  };

  return {
    getProductLinkParams,
  };
};

export const ProductPageLink: FC<IProductPageLinkProps> = ({
  asButton,
  href,
  variants,
  rentalStationId,
  deliveryAddressId,
  tenantKey,
  children,
  productGroupId,
  basketItemId,
  title,
  buttonProps,
  onClick,
  ariaLabel,
  shippingOptions,
}) => {
  const setScrollPosition = useSetRecoilState(scrollPositionState);
  const { getProductLinkParams } = useProductLink();

  // Render LinkButton when asButton is set to true
  if (true === asButton) {
    return (
      <LinkButton
        href={getProductLinkParams({ href, variants, rentalStationId, deliveryAddressId, tenantKey, basketItemId, shippingOptions })}
        onClick={onClick}
        {...buttonProps}
        fullWidth={true}
      >
        {children}
      </LinkButton>
    )
  }

  return (
    (<Link
      href={getProductLinkParams({ href, variants, rentalStationId, deliveryAddressId, tenantKey, basketItemId, shippingOptions })}
      as={''}
      shallow={false}
      passHref={true}
      scroll={true}
      title={title}
      aria-label={ariaLabel}
      onClick={(): void => {
        onClick && onClick();
        setScrollPosition(productGroupId ?? '');
      }}>

      {children}

    </Link>)
  );
};
